/* NavBar */
a.nav-link {
  font-size: 0.925rem;
  padding-left: 0.4em !important;
  padding-right: 0.4em !important;
  padding-top: 0.2rem;
  padding-bottom: 0.3em !important;
  margin-left: 2em;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  color:#fff;
}

.fs-7 {
  font-size: 0.75rem;
}

.navbar-dark .navbar-nav a.nav-link:hover{
  color:#64a4db;
}

.navbar-dark .navbar-nav a.nav-link.active {
  color:#64a4db;
}

#topnav {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .26);
  z-index: 999
}

#offcanvasnav {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .26);
  z-index: 999
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

#offcanvasNavbar a,
#offcanvasNavbar div {
  margin-left: 0em;
  font-size: 1.1rem;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

.mainpage {
  flex-grow: 1;
}

/* Breadcumb*/
.breadcrumb {
  background-color: transparent !important;
}

.breadcrumb a {
  color: #4c65c7;
  font-weight: 600;
}

.breadcrumb a:hover {
  color: #225499;
  text-decoration: underline;
}

/* Footer */
.footerlink:hover,
#footer .footerlink.active {
  color: #2f78e2 !important;
  background-color: transparent;
}

.invertcolor{
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

/* TabComponent */
.nav-tabs .nav-link,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active,
.nav-tabs a.nav-link:focus,
.nav-tabs .nav-link.active:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0.7em;
  padding-left: 1.7em !important;
  padding-right: 1.7em !important;
  border-top: rgba(255, 255, 255, 0) !important;
  border-left: rgba(255, 255, 255, 0) !important;
  border-right: rgba(255, 255, 255, 0) !important;
  line-height: 30px;
  background-image: none;
}

.nav-tabs .nav-link.active,
.nav-tabs a.nav-link:focus,
.nav-tabs .nav-link.active:hover {
  border-bottom: 2px solid #3954ab !important;
  cursor: default;
  color: #000;
}

.nav-tabs .nav-link:hover {
  border-bottom: 2px solid #c0c0c0 !important;
  color: #000;
  cursor: pointer;
  background-color: #eaeef155  !important;
}

.bgfull {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  margin-bottom: -1px;
}

.nav-item .counter {
  background-color: #d0d0d0;
  font-weight: 400;
  margin-left: 0.4em;
}

.table .progress {
  height: 1.25rem;
}

.progress-bar {
  overflow: visible !important;
  white-space: nowrap !important;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

/**** Bootstrap Table ****/
.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table td,
.react-bootstrap-table td a {
  font-weight: 600;
  vertical-align: middle !important;
  text-decoration: none;
}

.table {
  --bs-table-striped-bg: #f9f9fa;
}

.table td:has(a svg), th:has([data-accessor="sticky"]) {
  position: sticky;
  left: 0;
  background-color: var(--bs-body-bg);
  z-index: 10;
}

  table.table tr.bg-sponsored td, table.table tr.bg-sponsored td.freeze {
 background-color: #eaf3ff !important;
 white-space: normal;
}

html[data-bs-theme="dark"] table.table tr.bg-sponsored td {
      background-color: #0c316c !important; 
  }

.react-bootstrap-table th.sortable, .react-bootstrap-table tr.header-class th{
  border-top: 0 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.83rem;
  box-sizing: border-box;
}

.react-bootstrap-table  #table-pagination .btn-pagination.btn:hover{
  box-shadow: 0 0 !important;
  background-color: #eeeeeeaa;
}

.react-bootstrap-table  #table-pagination .btn-pagination.btn.active{
  background-color: #3954ab;
  color:white!important;
  box-shadow:0!important;
}
.react-bootstrap-table   #table-pagination .btn-pagination.btn{
  background-color: transparent;
}

td.max-min span {
  background-image: linear-gradient(111deg, #95e4ff, #76ddff);
}

.coinlogo {
  margin-top: 0.5rem;
  height: 66px;
  width: 66px;
}

.searchad {
  height: 35px;
  width: 35px;
  margin-right: 0.8em;
}

.searchbarlogo {
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
}

.tablelogo {
  height: 25px;
  width: 25px;
  margin-right: 0.5em;
}

.symbolmarketlogo {
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
}

.exchangelogo {
  height: 60px;
  width: 60px;
}

.sponsored {
  color: #3160d5;
  background-color: #bbcffc;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

tbody .small {
  font-size: 90%
}

/* Switch Toggle button*/
#table-map,
#anual-mensal, .custom-switch .form-check-input {
  width: 3em!important;
  height: 1.5em;
}

#table-map:checked,
#anual-mensal:checked, .custom-switch .form-check-input:checked {
  background-color: rgb(57, 84, 171);
  border:0
}

@media only screen and (min-width: 1026px) {
  #table-map {
    display: none;
  }
}

.assinebanner {
  position: relative;
  top: 6px;
}

/* APEXCHARTS */
.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: white !important;
  font-weight: 600;
}

#chartContainer .apexcharts-tooltip {
  color: #000000;
  overflow: visible;
}

.apexcharts-treemap-rect:hover {
  cursor: pointer;
}

.monthcase:first-letter {
  text-transform: uppercase;
}

#donut .apexcharts-legend-marker {
  display: none;
  height: 0% !important;
}

/* Search */
#navSearch .searchbar,  .searchbar {
  width: 17rem;
  outline: none !important;
  box-shadow: none!important;
}

.searchdropdown {
  z-index: 9999;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  position: absolute;
  top: 71%;
  flex-direction: column;
  margin: 0px;
  margin-left: 0rem;
  width: 17rem;
  padding-top: 1em;
  padding-bottom: 1rem;
  max-height: 18.3rem;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  line-height: 1.8;
}

.searchdropdown .nav-link {
  margin-left: 0px !important;
  font-weight: 600;
  background-image: none;
  color: #325b8f;
}

.searchdropdown a.nav-link {
  padding-left: 1rem !important;
}

#searchbar-dropdown a.nav-link:hover,
#searchbar-dropdown .nav-link:active,
#searchbar-dropdown .nav-link:focus {
  background-color: #e9ecefaa !important;
}

#searchbar-dropdown::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5f5;
}

#searchbar-dropdown::-webkit-scrollbar-track {
  border-radius: 10px;
}

#searchbar-dropdown::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 10px;
}

#offcanvasNavbar .searchdropdown {
  top: 12rem;
}

#offcanvasNavbar .searchbar,
#offcanvasNavbar .searchdropdown {
  width: 20rem;
}

.alert-info,
div.bd-callout {
  border-width: 0px 0px 0px 5px;
  border-radius: 0;
}

.codeblock {
  background-color: rgb(35, 35, 39);
  color: rgb(117, 191, 255)
}

.int {
  color: rgb(134, 222, 116)
}

.string {
  color: rgb(255, 125, 233)
}

.maintext {
  font-weight: 500;
  font-size: 16px;
}

.card-img-top {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

/* LivecoinsBar*/
#livecoinsbar {
  font-size: 98%;
  height: 3.2rem;
  padding: 0;
  vertical-align: middle;
  box-shadow: 0 0 1px 1px rgba(29, 17, 51, .04), 0 0 3px 2px rgba(9, 32, 77, .12), 0 0 2px -3px rgba(29, 17, 51, .12);
}

#livecoinsbar .nav-link {
  color: black;
  margin-left: 2em;
  font-weight: 600;
  background-image: none;
}

#livecoinsbar .nav-link:hover {
  color: #db4e5a;
}

#livecoinsbar .dropdown-item {
  font-size: 82%;
  font-weight: 600;
}

#livecoinsbar .dropdown-item:hover {
  background-color: white;
  color: #db4e5a;
}

.iconslive {
  margin: 0 !important;
}

.icontable {
  transform: scale(0.7);
  vertical-align: middle
}

.icon-rotated {
  transform: scale(0.7) rotate(180deg);
}

.icon-rotated-offcanvas {
  transform: scale(0.7) rotate(90deg);
}

.btn.btn-border-2 {
  border: 2px solid;
  font-weight: 500;
}

.shadow-edge {
  box-shadow: 0 9px 11.5px -3px rgba(0, 0, 0, .1), 0 18.5px 28.5px 0 rgba(0, 0, 0, .034), 0 7px 37.5px 0 rgba(0, 0, 0, .026);
}

.back-to-top-wrapper {
  z-index: 100;
  top: 40vh;
  bottom: 5em;
  width: 3em;
  pointer-events: none;
}

.scrolltotop {
  bottom: 30px;
  width: 40px;
  height: 40px;
  padding: 16px 16px;
  pointer-events: all;
  top: calc(100vh - 5rem);
}

#topBtn {
  transform: translateY(-35px);
}

.openmarket-table td,
.openmarket-table tbody {
  padding: 0.2rem !important;
  background-color: rgba(0, 0, 0, 0) !important;

}

.underline-info {
  background-image: linear-gradient(transparent 0%, transparent calc(54% - 5px), rgb(96, 204, 240) calc(40% - 9px), rgb(255, 255, 255) 100%);
  background-size: 100% 200%;
}

.underline-success {
  background-image: linear-gradient(transparent 0%, transparent calc(54% - 5px), #1eb980 calc(40% - 9px), #1eb980 40%);
  background-size: 100% 200%;
}

/* 404 */
#p404 .text-primary {
  font-size: calc(3rem + 8vw);
  font-weight: 900;
}

/* MOBILE */
@media only screen and (max-device-width: 800px) {

  /* For mobile phones: */
  .back-to-top-wrapper {
    right: 1em;
    bottom: 3em;
  }

  .symbolmarket-responsive {
    margin-left: 1.5rem !important;
  }

  .scrolltotop {
    right: 10px;
  }
}