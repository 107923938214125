// change the theme
$theme-colors: (
  "primary": #3954ab,
  "warning": #ff9800 ,
  "info": #39c0ed,
  "light":#f8f9fa,
  "secondary": #2f78e2,
  "success": #00a83e,
  "danger":  #dc3545,
  "gray":  #8d8f91,
  "dark": #262626,
  "purple": #6400b2,
  
//other
"excel": #107840,

 // ads //
 "binance":#f0b90b,
  "lemon":#2bfd3c,
  "astropay": #f90103,
  "greenlime": #11af30,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import './index.css'